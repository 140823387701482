import { useState,useEffect,useRef } from 'react'

import styles from "../index.module.css";
import "../styles/audio-recorder.css";
import AudioRecorder from './AudioRecorder.tsx';
import ChatHistory from './ChatHistory.tsx';
import TextToSpeech from './TextToSpeech.tsx';

import micSVG from "../icons/mic.svg";
import { API_URL, TTS_API_URL, WSS_URL, HOST_INFO } from "./Utils";


export default function Affirmation() {
	const [promptInput, setPromptInput] = useState("");
	const [result, setResult] = useState("Ten Genius Labs");
	// const [result, setResult] = useState("Ten Genius Labs: Advancing AI Frontiers with Exceptional Minds");
	const [isRecording, setIsRecording] = useState(false);
	const [mediaStream, setMediaStream] = useState(null);
	const [socket, setSocket] = useState(null);
	const [message, setMessage] = useState(null);
	const inputRef = useRef(null);
	const [messages, setMessages] = useState([]);
	const [user, setUser] = useState('');

	const audioRef = useRef(null);

	const handlePause = () => {
	  if (audioRef.current) {
		audioRef.current.pause();
	  }
	}
	// console.log("process.env.AMA_HOST_WHO:",process.env.AMA_HOST_WHO);
	// console.log("process.env.REACT_APP_PROD_API_URL:",process.env.REACT_APP_PROD_API_URL);

	useEffect(() => {
		setUser("user");
	
		// if (inputRef.current !== null) {
		//   inputRef.current.selectionStart = promptInput.length;
		//   inputRef.current.selectionEnd = promptInput.length;
		//   inputRef.current.focus();
		//   		 				//console.log("setting focus");

		// }
	  }, []);

	  function generateKey(length) {
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		let key = '';
	  
		for (let i = 0; i < length; i++) {
		  const randomIndex = Math.floor(Math.random() * characters.length);
		  key += characters.charAt(randomIndex);
		}
	  
		return key;
	  }
	  
	  async function askGPTNextQuestion() {
		if (promptInput === ''){
		speakTextZh('请录入中文');
		startRecording()
		}
	 else{ 
		if (messages.length >1 && messages[messages.length - 2].text ==  promptInput) {
			startRecording()
		} else {
		askGPTCheckAnswer(promptInput,promptInput);
	}
	 }
	  }
	  async function askGPTCheckAnswer(answer,prompt_current) {
		answer = answer.trim()
		if (answer.length >0) {
			messages.push({
				text: answer,
				user: user,
			  });
		} else {
			return
		}
		
		prompt = 'please translate to english: '+ answer +". please return only the translated words and if you cannot translate, return <>";
		askGPT(prompt,prompt_current)
	  }
	  async function speakTextZh(msg) {
		//text to voice
		if ('speechSynthesis' in window) {

			let	messge_ref = new SpeechSynthesisUtterance();
		  const voices = window.speechSynthesis.getVoices()
		  let selectedVoice = voices.find(voice => voice.name.includes('普通话'));
		  messge_ref.voice = selectedVoice;
		  messge_ref.lang = 'zh';
		messge_ref.text = msg
			window.speechSynthesis.speak(messge_ref);
		  } else {
			alert('Sorry, your browser doesn\'t support the Web Speech API');
		  }
}
	  async function speakText(result) {
						//text to voice
						if ('speechSynthesis' in window) {
							let messge_ref = message
							if(!messge_ref) {
								messge_ref = new SpeechSynthesisUtterance();
						  const voices = window.speechSynthesis.getVoices()
						  let selectedVoice = voices.find(voice => voice.name.includes('en-US'));
						  messge_ref.voice = selectedVoice;
						  messge_ref.lang = 'en-US';
							setMessage(messge_ref)
						}
						messge_ref.text = result
						  //   console.log("speechSynthesis msg:",messge_ref.text);
							window.speechSynthesis.speak(messge_ref);
						  } else {
							alert('Sorry, your browser doesn\'t support the Web Speech API');
						  }
	}
	async function stopRecording() {
		if (mediaStream){        
			mediaStream.getTracks().forEach((track) => {
			  //console.log("track.stop:",track)
			  track.stop();
			});
			setMediaStream(null)
		  }
		setIsRecording(false);
	}
	  async function askGPT(prompt,prompt_current) {
		if (prompt===''){
			return
		}
		// messages.push({
		// 	text: prompt_current,
		// 	user: user,
		// });
	  
		if (prompt.endsWith('Stop.') || prompt.endsWith('Stop')){
			handlePause() 
			return
		}
		
		stopRecording()

		if (prompt.endsWith('Goodbye.') || prompt.endsWith('Goodbye')){
			return
		}
		try {
			const response = await fetch(`${API_URL}/generate`, {
			  method: "POST",
			  //mode: "no-cors",
			  headers: {
				"Content-Type": "application/json",
			  },
			  body: JSON.stringify({ prompt: prompt }),
			});
	  
			const data = await response.json();
			if (response.status !== 200) {
			  throw data.error || new Error(`Request failed with status ${response.status}`);
			}
			//console.log("returned data:",data.result)
			let result = data.result.content
			if (result.startsWith("?")) {
				result = result.substring(1)
			}
			if (result.startsWith(".")) {
				result = result.substring(1)
			}
			if (result.trim() === '') {
				return
			}
			if (result.trim().startsWith('<')) {
				return
			}
			setResult(result);
			messages.push({
				text: result,
				user: "PI",
			  });
			if (false) {
			  try {
				const randomId = generateKey(36)
				  const response = await fetch(`${TTS_API_URL}/api`, {
					method: "POST",
					//mode: "no-cors",
					headers: {
					  "Content-Type": "application/json",
					},
					body: JSON.stringify({ x: result,y: randomId,k:0 }),
				  });
				  if (response.status !== 200) {
					  throw new Error(`Request failed with status ${response.status}`);
					}
					//console.log("response:",response)
					handlePause() 
			const audioUrl =`${TTS_API_URL}/api/`+randomId;
			const audio = new Audio(audioUrl);
			audioRef.current = audio
			audio.play();
			
			  			//setPromptInput("");
			  } catch(error) {
				  // Consider implementing your own error handling logic here
				  console.error(error);
				  //alert(error.message);
				}
			  
			  
			} else {
				speakText(result)
			  }
		  } catch(error) {
			// Consider implementing your own error handling logic here
			console.error(error);
			alert(error.message);
		  }
	  }
	async function onSubmit(event) {
		event.preventDefault();
		// askGPTNextQuestion();
		speakText('Welcome to Ten Genius AI');
		// URL you want to navigate to
		const url = 'https://vv.tengenius.ai';

		// Navigate to the new URL
		window.location.href = url;
	  }

	async function startRecording() {
		const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
 setMediaStream(stream)
	//console.log("init socket:");
	//const socket = new WebSocket("wss://chocofrog.com/ws")
	const socket = new WebSocket(WSS_URL)
 setSocket(socket)
		setIsRecording(true)
	}
		
return(

	<div>
	{/* <Head>
	  <title>ChatGPT</title>
	  <link rel="icon" href="/images.png" />
	</Head> */}

	<main className={styles.main}>
	  <img src="/images.png" alt="baby genius" className={styles.icon} />
	  <div className={styles.result}>{result}</div>
			  {/* <ChatHistory messages={messages} currentUser={user} /> */}
			  {/* {result&&<div><TextToSpeech text={result}/></div>} */}
			  {/* {<div><VoicesApp /></div>} */}
			   <form onSubmit={onSubmit}>
          {/* <input
            type="text"
			ref={inputRef} 
            name="prompt"
            placeholder="More to come"
            value={promptInput}
            onChange={(e) => {setPromptInput(e.target.value)
			}}
          /> */}
          {!isRecording &&<input type="submit" value={HOST_INFO} />}
        </form>
		{/* {isRecording && <AudioRecorder askGPT={askGPTCheckAnswer} updateState={setPromptInput} stream={mediaStream} socket={socket} updateRecording={setIsRecording} updateMedia={setMediaStream} updateSocket={setSocket}/>} */}
		{/* {!isRecording && <div>
				<img
				  src={micSVG}
				  className={`audio-recorder-mic`}
				 onClick={startRecording}
				  data-testid="ar_mic"
				 title={"Start recording"}
				/>
			 </div>} */}

		 </main>
    </div>
)
}