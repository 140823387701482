

export const API_URL =
process.env.NODE_ENV === "development"
  ? "http://localhost:3001/api"
  : process.env.REACT_APP_PROD_API_URL;

  export  const WSS_URL =
process.env.NODE_ENV === "development"
  ? "ws://localhost:3002"
  : process.env.REACT_APP_PROD_API_URL_WS;

  // export  const  API_URL = process.env.REACT_APP_PROD_API_URL;
  // export  const WSS_URL = process.env.REACT_APP_PROD_API_URL_WS;

 // console.log("process.env.AMA_HOST:",process.env.AMA_HOST);
 // console.log("process.env.REACT_APP_PROD_API_URL:",process.env.REACT_APP_PROD_API_URL);
 //console.log("process.env.REACT_APP_PROD_API_URL_WS:",process.env.REACT_APP_PROD_API_URL_WS);


export const HOST_INFO ='Enter';

export const TTS_API_URL = "https://137.chocofrog.com";

console.log("API_URL:",API_URL);
console.log("WSS_URL:",WSS_URL);
// console.log("HOST_INFO:",HOST_INFO);
// console.log("TTS_API_URL:",TTS_API_URL);

export function stopRecording (stream) {
    // if (mediaRecorder && mediaRecorder.state === "recording") {
    //   mediaRecorder.stop();
    // }
    // if (mediaRecorder){
    //   mediaRecorder.removeEventListener('dataavailable', async (event) => {})
    // }
   
    // navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
    //   console.log("trying track.stop:")
    if (stream){        
        stream.getTracks().forEach((track) => {
          //console.log("track.stop:",track)
          track.stop();
        });
      }
  
    //window.location.reload();
  
  };