import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Affirmation from './components/Affirmation';
import reportWebVitals from './reportWebVitals';
import VoiceRecorder from './components/VoiceRecorder.tsx';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
    <div>
      <Routes>
        {/* <Route path="/voice" element={<VoiceRecorder />} /> */}
         
        <Route path="/" element={
        <><Affirmation />
        {/* <div className="voice-page-container"> <Link to="/voice">Train Your Own Voice</Link>
                    </div> */}
                    </>}
        />
      </Routes>
    </div>
  </Router>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
